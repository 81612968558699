/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCRY5XxMpUMZxQaVHDglfMdkQNX5GspWdc",
  "appId": "1:901154596462:web:41d27f2fbc9ee75dfa687f",
  "authDomain": "schooldog-i-glynn-ga.firebaseapp.com",
  "measurementId": "G-LEGP25DWM1",
  "messagingSenderId": "901154596462",
  "project": "schooldog-i-glynn-ga",
  "projectId": "schooldog-i-glynn-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-glynn-ga.appspot.com"
}
